<template lang="">
  <section>
    <p class="text-h6 secondary--text">Evaluaciones PEO</p>
    <v-row>
      <v-col cols="12">
        <p>Procesos disponibles</p>
        <data-table-component
          :headers="encabezadoTabla"
          :items="datosTabla"
          v-models:select="porPaginaModel"
          v-models:pagina="paginaModel"
          :total_registros="totalRegistros"
          @paginar="paginar"
        >
          <!-- Template para acciones con un icon button con un ojo -->
          <template v-slot:item.acciones="{ item }">
            <!-- <v-icon class="mr-2" color="primary" @click="verGanadores(item)"
              >mdi-eye</v-icon
            > -->
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                  small
                  @click="verGanadores(item)"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </data-table-component>
      </v-col>
    </v-row>
  </section>
</template>
<script>
import DataTableComponent from "../../components/DataTableComponent.vue";
import moment from "moment";
export default {
  name: "evaluacionesPeoView",
  components: {
    DataTableComponent,
  },
  data() {
    return {
      encabezadoTabla: [
        {
          text: "Código",
          align: "start",
          sortable: false,
          value: "codigo",
        },
        { text: "Procesos", value: "procesos" },
        { text: "Modalidad de compra", value: "modalidad" },
        { text: "Etapa", value: "etapa" },
        { text: "Inicio de evaluación", value: "inicio" },
        { text: "Fin de evaluación de ofertas", value: "fin" },
        {
          text: "Acciones",
          value: "acciones",
          sortable: false,
          align: "center",
        },
      ],
      // datos de muestra
      datosTabla: [],
      porPaginaModel: 10,
      paginaModel: 1,
      totalRegistros: 0,
    };
  },
  methods: {
    verGanadores(item) {
      this.$router.push({
        name: "peo-ganadores",
        params: { id: item.id },
      });
    },
    paginar(filtros) {
      const { cantidad_por_pagina, pagina } = filtros;
      this.paginaModel = pagina;
      this.porPaginaModel = cantidad_por_pagina;
      this.getListadoProcesos();
    },
    async getListadoProcesos() {
      const response = await this.services.PeoServices.getListadoProcesos({
        pagination: true,
        page: this.paginaModel,
        per_page: this.porPaginaModel,
      });

      if (response.status === 200) {
        this.totalRegistros = parseInt(response.headers.total_rows);

        const { data } = response;
        this.datosTabla = data.map((item) => ({
          id: item.id,
          codigo: item.codigo_proceso,
          procesos: item.nombre_proceso,
          modalidad: item.FormaContratacion.nombre,
          etapa: item.EtapaPorProcesos[0].nombre,
          inicio: moment(item.EtapaPorProcesos[0].fecha_hora_inicio).format(
            "DD/MM/YYYY hh:mm A"
          ),
          fin: moment(item.EtapaPorProcesos[0].fecha_hora_fin).format(
            "DD/MM/YYYY hh:mm A"
          ),
          acciones: "Acciones",
        }));
      } else {
        this.temporalAlert({
          show: true,
          message: response.data.message,
          type: "error",
        });
      }
    },
  },
  computed: {},
  async mounted() {
    this.getListadoProcesos();
  },
};
</script>